import React, { useState } from "react";
import "./App.css";
import nitroLogo from "./components/icons/nitro-logo.svg";
import u from "./functions/utils.js";

function App() {
    const [isDisabled, setIsDisabled] = useState(false); // Manage disabled state
    const [errorMessage, setErrorMessage] = useState(""); // Manage error message visibility

    const handleSubmit = (e) => {
        e.preventDefault();

        const email = e.target.email.value;
        const password = e.target.password.value;

        // Simple validation
        if (email.trim() && password.trim()) {
            // Disable inputs and button
            setIsDisabled(true);

            // Re-enable inputs and button after 2 seconds and then display the error
            setTimeout(() => {
                setIsDisabled(false);
                setErrorMessage("Please contact us to reactivate your account");
            }, 2000);
        } else {
            alert("Please fill out both email and password fields.");
        }
    };

    const clearErrorMessage = () => {
        // Clear the error message when inputs or button are interacted with
        setErrorMessage("");
    };

    return (
        <div className="flex min-h-full flex-1 items-center justify-center px-4 py-[8vw] sm:px-6 lg:px-8">
            <div className="w-full max-w-sm space-y-10">
                <div>
                    <img src={nitroLogo} alt="Your Company Logo" className="mx-auto h-10 w-[148px]" />
                </div>
                <form
                    onSubmit={handleSubmit}
                    className="space-y-6"
                    onClick={clearErrorMessage} // Clear error when form is clicked
                >
                    <div>
                        <div className="col-span-2">
                            <input
                                id="email-address"
                                name="email"
                                type="email"
                                required
                                placeholder="Email address"
                                autoComplete="email"
                                aria-label="Email address"
                                className="block w-full rounded-t-md bg-white px-3 py-4 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:relative focus:outline-2 focus:-outline-offset-2 focus:outline-blu sm:text-sm/6"
                                disabled={isDisabled} // Disable input
                                onFocus={clearErrorMessage} // Clear error on focus
                                onBlur={clearErrorMessage} // Clear error on blur
                            />
                        </div>
                        <div className="-mt-px">
                            <input
                                id="password"
                                name="password"
                                type="password"
                                required
                                placeholder="Password"
                                autoComplete="current-password"
                                aria-label="Password"
                                className="block w-full rounded-b-md bg-white px-3 py-4 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:relative focus:outline-2 focus:-outline-offset-2 focus:outline-blu sm:text-sm/6"
                                disabled={isDisabled} // Disable input
                                onFocus={clearErrorMessage} // Clear error on focus
                                onBlur={clearErrorMessage} // Clear error on blur
                            />
                        </div>
                    </div>

                    <div>
                        <button
                            type="submit"
                            className={u.classNames(
                                "block w-full rounded-full bg-blu_bold p-4 text-center text-md cursor-pointer max-w-[400px]",
                                "text-white shadow-sm hover:bg-opacity-80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blu",
                                isDisabled ? "opacity-50 cursor-not-allowed" : "" // Add styles when disabled
                            )}
                            disabled={isDisabled} // Disable button
                            onClick={clearErrorMessage} // Clear error on button click
                        >
                            Sign in
                        </button>
                    </div>

                    {/* Error message */}
                    {!isDisabled && errorMessage && <div className="text-red-600 text-sm mt-2 text-center">{errorMessage}</div>}
                </form>
            </div>
        </div>
    );
}

export default App;
